<template>
    <b-container fluid id="footer" class="topLine">
      <b-container>
        <p>Telge Lackering AB</p>
        <div class="social"><a href="https://www.instagram.com/telge_lackering" target="_blank" title="Följ oss på Instagram"><img src="../assets/IG_Glyph_Fill.png" alt="Instagram"/></a></div>
        <b-row>
          <b-col sm>
            <ul>
              <li><a href="/privacy.html">Privacy policy</a></li>
            </ul>
          </b-col>
          <b-col sm class="text-sm-right">
            <ul>
              <li><router-link :to="{ name: 'contact' }">Kontakta oss</router-link></li>
            </ul>
          </b-col>
        </b-row>
        <p>Copyright © 2019, Telge Lackering AB</p>
      </b-container>
    </b-container>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#footer {
  padding-top: 10px;
  background-color: #fff;
  color: #747474;
  a {
    color: #747474;
  }
  p {
    text-align: center;
    font-size: 18px;
  }
}

.social {
  $width: 50px;

  margin: 20px auto;
  display: table;

  img {
    width: $width;
  }
  a {
    margin: $width/4;
  }
}

@media only screen and (max-width: 576px) {
  ul {
    padding: 0px;
    text-align: center;
  }
}
</style>
