<template>
  <div id="header">
    <b-navbar toggleable="md" type="light">
      <div class="container">
        <b-navbar-brand :to="{ name: 'home' }" style="margin-right:100px;"><img src="../assets/logo.png"
            alt="Telge Lackerings Logga"></b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item :to="{ name: 'home' }">Hem</b-nav-item>
            <b-nav-item :to="{ name: 'price' }">Prisräknare</b-nav-item>
            <b-nav-item :to="{ name: 'gallery' }">Galleri</b-nav-item>
            <b-nav-item :to="{ name: 'contact' }">Kontakt</b-nav-item>
            <b-nav-item :to="{ name: 'organization' }">Organisation</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
        <b-navbar-brand href="https://branschvinnare.se/" id="branchvinnare"><img src="../assets/branchvinnare.jpg"
            alt="Branchvinnare i Sverige AB">
        </b-navbar-brand> 
      </div>
    </b-navbar>
    <b-container fluid class="underLine"></b-container>
  </div>
</template>

<script>
import { BNavbar, BNavbarNav, BNavbarBrand, BNavbarToggle, BNavItem, BCollapse } from 'bootstrap-vue'
export default {
  name: 'Header',
  components: {
    BNavbar,
    BNavbarNav,
    BNavbarBrand,
    BNavbarToggle,
    BNavItem,
    BCollapse
  }
}
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#nav-collapse {
  margin-right: 100px;
}

#branchvinnare {
  margin-right: 50px;

  @media only screen and (max-width: 576px) {
    left: 0;
  }
}
</style>
